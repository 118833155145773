import React, { useEffect, useState, useRef } from "react";
import {
  View,
  Text,
  ScrollView,
  FlatList,
  TouchableOpacity,
  Platform
} from "react-native";
import {
  MaterialIcons,
  Ionicons,
  MaterialCommunityIcons
} from "@expo/vector-icons";
import Rex from "src/globalState";
import Firebase from "src/backend/firebase";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import FloatingActionButton from "src/components/FloatingActionButton";
import Icon from "src/components/Icon";
import Post from "src/components/activityFeeds/Post";
import NewPostModal from "src/components/activityFeeds/NewPostModal";
import PostSkeleton from "src/components/activityFeeds/PostSkeleton";
import HelpText from "src/components/HelpText";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

export default function ActivityFeed({
  route,
  navigation,
  portalContentOverride
}) {
  const {
    params: {
      txtName,
      navName,
      restrictedToFeedSubscribers,
      allowedAccountTypes
    } = {}
  } = route || {};
  const [feed, setFeed] = useState(null);
  const [config, setConfig] = useState(null);
  const [allUsers, setAllUsers] = useState(null);
  const [isComposingMessage, setIsComposingMessage] = useState(false);
  const [userID, setUserID] = useState(null);
  const [userName, setUserName] = useState("");
  const [activityFeedID, setActivityFeedID] = useState(null);
  const [loading, setLoading] = useState(true);
  const [canEdit, setCanEdit] = useState(false);
  const [canManageSubscribers, setCanManageSubscribers] = useState(false);
  const [canPost, setCanPost] = useState(false);
  const [canComment, setCanComment] = useState(false);
  const [alert, setAlert] = useState(null);

  const activityFeedIDRef = useRef(null);

  useEffect(() => {
    const privileges = Rex.getSessionMemory("adminPrivileges");
    Database.fetchPortalEditors(navName).then((editors) => {
      const userCanEditThisSpecificPortal =
        editors && editors[Firebase.getUserID()] === true;
      setCanEdit(
        privileges?.includes("EditAllPortals") || userCanEditThisSpecificPortal
      );
      setCanManageSubscribers(
        privileges?.includes("ManageUsers") ||
          privileges?.includes("EditAllPortals") ||
          userCanEditThisSpecificPortal
      );
    });
    Database.getPortalContentNew(navName).then((contentFetched) => {
      const content = portalContentOverride || contentFetched;
      const {
        activityFeed = null,
        title,
        backgroundColor,
        feedTopic,
        feedType,
        disableLikes,
        disableComments,
        accountTypesAllowedToPost,
        accountTypesAllowedToComment
      } = content;
      Database.fetchAllUserData().then((userData) => {
        setUserName(`${userData?.firstName || ""} ${userData?.lastName || ""}`);
        const { type } = userData || {};
        if (
          Rex.getLoginStatus() &&
          (!accountTypesAllowedToPost || accountTypesAllowedToPost?.[type])
        )
          setCanPost(true);
        if (
          Rex.getLoginStatus() &&
          (!accountTypesAllowedToComment ||
            accountTypesAllowedToComment?.[type])
        )
          setCanComment(true);
      });
      setConfig({
        title,
        backgroundColor,
        feedTopic,
        feedType,
        disableLikes,
        disableComments,
        accountTypesAllowedToPost,
        accountTypesAllowedToComment
      });
      if (activityFeed) {
        Analytics.logEvent("view_activityFeed", {
          activityFeedID: activityFeed,
          navName
        });
        setActivityFeedID(activityFeed);
        activityFeedIDRef.current = activityFeed;
        Database.subscribeToActivityFeed(activityFeed, onFeedUpdated);
      } else {
        setLoading(false);
      }
    });
    Database.fetchAllUsers(true, true).then((userList) => {
      const userObj = {};
      userList.forEach((user) => {
        userObj[user.uid] = user;
      });
      setAllUsers(userObj);
    });
    setUserID(Firebase.getUserID());

    // cleanup called when component is unmounting
    return () => {
      if (activityFeedIDRef.current) {
        Database.unsubscribeFromActivityFeed(activityFeedIDRef.current);
      }
    };
  }, []);

  const onFeedUpdated = (newFeed) => {
    setLoading(false);
    setFeed(newFeed);
  };

  const onPressNewPostButton = () => {
    Analytics.logEvent("touch_activityFeed_pressNewPostButton");
    setIsComposingMessage(true);
  };

  // newPDF should be { url: string, name: string }
  const onSubmitNewPost = (newMessage, newMedia = null, newPDF = null) => {
    console.log("newPDF", newPDF);
    Analytics.logEvent("touch_activityFeed_postNewMessage", {
      activityFeedID,
      message: newMessage,
      ...(newMedia ? { media: newMedia } : {}),
      ...(newPDF && newPDF.url ? { links: [newPDF] } : {}),
      navName
    });
    if (userID === Glob.get("demoAccountUserID")) {
      setAlert({
        title: "Demo: Posted Message",
        message: `Message: ${newMessage}`
      });
      setIsComposingMessage(false);
    } else {
      Database.postToActivityFeed(activityFeedID, navName, {
        message: newMessage,
        ...(newMedia ? { media: newMedia } : {}),
        ...(newPDF && newPDF.url ? { links: [newPDF] } : {})
      }).then((response) => {
        if (response?.success) setIsComposingMessage(false);
      });
    }
  };

  const pressSettingsButton = () => {
    Analytics.logEvent("touch_activityFeed_pressSettingsButton");
    const { icon, portalType, onUpdatePortal } = route?.params || {};
    navigation.goBack();
    navigation.push("editPortal", {
      portalMetadata: {
        navName,
        icon,
        portalType,
        txtName,
        restrictedToFeedSubscribers,
        allowedAccountTypes
      },
      onUpdatePortal
    });
  };

  const onPressSubscribeToNotifications = (subscribe = true) => {
    Analytics.logEvent("touch_activityFeed_pressSubscribeToNotifications", {
      activityFeedID,
      subscribe
    });
    Database.subscribeToReceiveActivityFeedNotifications(
      activityFeedID,
      subscribe
    );
  };

  const posts = feed?.posts
    ? Object.entries(feed.posts)
        .map(([postID, post]) => ({
          ...post,
          postID,
          user: allUsers?.[post.userID] || {}
        }))
        .sort((a, b) => {
          return new Date(b.timestamp) - new Date(a.timestamp);
        })
    : [];

  const { subscribers } = feed || {};
  const userIsSubscriber = subscribers?.[userID];

  const isChatStyle = config?.feedType === "chat";

  let RightButton = null;
  let RightButtonSecondary = null;
  if (Rex.getLoginStatus())
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingRight: 20
        }}
        activeOpacity={0.6}
        onPress={() => {
          onPressSubscribeToNotifications(!userIsSubscriber);
        }}
      >
        <MaterialCommunityIcons
          name={userIsSubscriber ? "bell-check-outline" : "bell-plus-outline"}
          size={Glob.deviceHasTabletDimensions() ? 42 : 26}
          color="white"
        />
      </TouchableOpacity>
    );

  if (canManageSubscribers)
    RightButton = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end",
          paddingRight: 20
        }}
        activeOpacity={0.6}
        onPress={() =>
          navigation.push("activityFeedManageUsers", {
            activityFeedID,
            isChatStyle,
            restrictedToFeedSubscribers
          })
        }
      >
        <Icon
          icon="4884172e-8830-4581-9ee8-9f08924c35ea"
          color="white"
          size={Glob.deviceHasTabletDimensions() ? 42 : 26}
        />
      </TouchableOpacity>
    );
  if (canEdit)
    RightButtonSecondary = (
      <TouchableOpacity
        style={{
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "flex-end"
        }}
        activeOpacity={0.6}
        onPress={pressSettingsButton}
      >
        <MaterialIcons
          name="settings"
          size={Glob.deviceHasTabletDimensions() ? 42 : 26}
          color="white"
        />
      </TouchableOpacity>
    );

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: isChatStyle
          ? "white"
          : config?.backgroundColor || "#f4f2ee"
      }}
    >
      <NavBar
        navigation={navigation}
        text={config?.title || "Community"}
        RightButton={RightButton}
        RightButtonSecondary={RightButtonSecondary}
      />
      <View style={{ flex: 1 }}>
        {!userIsSubscriber && !loading && !!Rex.getLoginStatus() && (
          <View
            style={{
              width: "100%",
              backgroundColor: "white",
              alignItems: "center"
            }}
          >
            <View style={{ width, paddingHorizontal: 20, paddingVertical: 10 }}>
              <HelpText
                text="You're not subscribed to get notified about new messages posted here."
                noteStyle
              />
              <Button
                text="Subscribe to Updates"
                flat
                icon="9f4b25d6-71f3-4659-ab56-1fa44e1a485e" // bell
                onPress={() => onPressSubscribeToNotifications(true)}
                textStyle={{ fontWeight: "bold" }}
              />
            </View>
          </View>
        )}
        {!!config?.feedTopic && (
          <View
            style={{
              alignItems: "center",
              maxHeight: "25%",
              backgroundColor: "white",
              borderBottomWidth: 1,
              borderBottomColor: "#aaa"
            }}
          >
            <ScrollView contentContainerStyle={{ padding: 20, width }}>
              <Text style={{ fontWeight: "bold", fontSize: 18 }}>
                {config?.feedTopic}
              </Text>
            </ScrollView>
          </View>
        )}
        {loading || !posts || posts.length < 1 ? (
          <View style={{ alignItems: "center", width: "100%" }}>
            {loading ? (
              <ScrollView
                contentContainerStyle={{
                  flex: 1,
                  width,
                  justifyContent:
                    isChatStyle && Platform.OS !== "web"
                      ? "flex-end"
                      : "flex-start",
                  paddingBottom: 60
                }}
                scrollIndicatorInsets={{ right: 1 }}
              >
                <PostSkeleton />
                <PostSkeleton />
                <PostSkeleton />
              </ScrollView>
            ) : (
              <View style={{ alignItems: "center", marginTop: 50 }}>
                <Text>Be the first to post!</Text>
              </View>
            )}
          </View>
        ) : (
          <FlatList
            inverted={isChatStyle && Platform.OS !== "web"}
            contentContainerStyle={{
              marginTop: 10,
              paddingBottom: isChatStyle ? 0 : 40,
              width: "100%",
              alignItems: "center"
            }}
            scrollIndicatorInsets={{ right: 1 }}
            data={posts || []}
            renderItem={({ item }) => (
              <Post
                activityFeedID={activityFeedID}
                post={item}
                postID={item?.postID}
                navigation={navigation}
                allUsers={allUsers}
                userID={userID}
                userName={userName}
                isChatStyle={isChatStyle}
                disableLikes={config?.disableLikes}
                disableComments={config?.disableComments}
                canComment={canComment}
                portalID={navName}
                setAlert={setAlert}
              />
            )}
            keyExtractor={(item) => item.postID}
          />
        )}
      </View>
      {!!canPost && (
        <>
          {isChatStyle ? (
            <>
              {Platform.OS === "web" && <View style={{ height: 120 }} />}
              <View
                style={{
                  paddingTop: 10,
                  paddingBottom: Platform.OS === "web" ? 10 : 50,
                  paddingHorizontal: 15,
                  backgroundColor: "white",
                  shadowOpacity: 0.08,
                  shadowOffset: { width: 0, height: -5 },
                  shadowRadius: 7,
                  elevation: 20,
                  ...(Platform.OS === "web"
                    ? {
                        boxShadow: "0px -5px 3px rgba(0,0,0,0.08)",
                        height: 120,
                        bottom: 0,
                        position: "fixed"
                      }
                    : {}),
                  width: "100%"
                }}
              >
                <Button
                  text="New Message"
                  onPress={onPressNewPostButton}
                  icon="0a94f591-4df6-4f80-abc5-2c374cf25eb9"
                  textStyle={{ fontWeight: "bold" }}
                />
              </View>
            </>
          ) : (
            <FloatingActionButton
              icon={<Ionicons name="create-outline" size={40} color="white" />}
              onPress={onPressNewPostButton}
            />
          )}
        </>
      )}
      {!!isComposingMessage && (
        <NewPostModal
          onClose={() => setIsComposingMessage(false)}
          userName={userName}
          onSubmit={onSubmitNewPost}
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
