import React, { useEffect, useState } from "react";
import { View, FlatList, Platform, Text } from "react-native";
import Analytics from "src/backend/analytics";
import Database from "src/backend/database";
import NavBar from "src/components/navBar";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import Post from "src/components/activityFeeds/Post";
import NewPostModal from "src/components/activityFeeds/NewPostModal";
import AlertModal from "src/components/AlertModal";

const { width } = Glob.get("dimensions");

export default function ActivityFeedComments({ route, navigation }) {
  const {
    params: {
      activityFeedID,
      postID,
      postUser,
      allUsers,
      userID,
      userName,
      isChatStyle,
      disableLikes,
      disableComments,
      canComment,
      portalID
    } = {}
  } = route || {};
  const [post, setPost] = useState(null);
  const [isComposingMessage, setIsComposingMessage] = useState(false);
  const [alert, setAlert] = useState(null);

  useEffect(() => {
    Analytics.logEvent("view_activityFeedComments", { activityFeedID, postID });
    if (postID) {
      Database.subscribeToActivityFeedPost(
        activityFeedID,
        postID,
        onPostUpdated
      );
    }

    // cleanup called when component is unmounting
    return () =>
      Database.unsubscribeFromActivityFeedPost(activityFeedID, postID);
  }, []);

  const onPostUpdated = (newPost) => {
    setPost({ ...newPost, user: postUser });
  };

  const onPressNewPostButton = () => {
    Analytics.logEvent("touch_activityFeedComments_pressNewPostButton");
    setIsComposingMessage(true);
  };

  // newPDF should be { url: string, name: string }
  const onSubmitNewComment = (newMessage, newMedia = null, newPDF = null) => {
    Analytics.logEvent("touch_activityFeedComments_postNewComment", {
      activityFeedID,
      postID,
      message: newMessage,
      ...(newMedia ? { media: newMedia } : {}),
      ...(newPDF && newPDF.url ? { links: [newPDF] } : {})
    });
    if (userID === Glob.get("demoAccountUserID")) {
      setAlert({
        title: "Demo: Posted Comment",
        message: `Message: ${newMessage}`
      });
      setIsComposingMessage(false);
    } else {
      Database.postCommentToActivityFeed(activityFeedID, postID, portalID, {
        message: newMessage,
        ...(newMedia ? { media: newMedia } : {}),
        ...(newPDF && newPDF.url ? { links: [newPDF] } : {})
      }).then((response) => {
        if (response?.success) setIsComposingMessage(false);
      });
    }
  };

  const comments = post?.comments
    ? Object.entries(post.comments)
        .map(([commentID, comment]) => ({
          ...comment,
          commentID,
          user: allUsers?.[comment.userID] || {}
        }))
        .sort((a, b) => {
          return new Date(a.timestamp) - new Date(b.timestamp);
        })
    : [];

  let NoCommentsMessage = () => null;
  if (!disableComments) {
    if (canComment) {
      NoCommentsMessage = () => (
        <Text style={{ color: "#8D969D" }}>
          Be the first to {isChatStyle ? "reply" : "comment"}!
        </Text>
      );
    } else {
      NoCommentsMessage = () => (
        <Text style={{ color: "#8D969D" }}>
          No {isChatStyle ? "replies" : "comments"} yet
        </Text>
      );
    }
  }

  return (
    <View
      style={{
        flex: 1,
        backgroundColor: "white",
        alignItems: "center"
      }}
    >
      <NavBar navigation={navigation} text="Comments" />
      <View style={{ flex: 1 }}>
        <FlatList
          contentContainerStyle={{
            paddingBottom: 40,
            width: "100%",
            alignItems: "center"
          }}
          scrollIndicatorInsets={{ right: 1 }}
          data={["EXPANDED_POST", ...(comments || [])]}
          renderItem={({ item }) => {
            if (item === "EXPANDED_POST") {
              return (
                <>
                  <Post
                    post={post}
                    activityFeedID={activityFeedID}
                    postID={postID}
                    userID={userID}
                    navigation={navigation}
                    isExpandedView
                    isChatStyle={isChatStyle}
                    disableLikes={disableLikes}
                    disableComments={disableComments}
                    setAlert={setAlert}
                  />
                  {(!comments || comments.length < 1 || disableComments) && (
                    <View style={{ alignItems: "center", marginVertical: 50 }}>
                      <NoCommentsMessage />
                    </View>
                  )}
                </>
              );
            }
            return (
              <View style={{ width, alignItems: "flex-end" }}>
                <Post
                  activityFeedID={activityFeedID}
                  postID={postID}
                  userID={userID}
                  post={item}
                  navigation={navigation}
                  commentID={item?.commentID}
                  disableLikes={disableLikes}
                  setAlert={setAlert}
                />
              </View>
            );
          }}
          keyExtractor={(item) => item.commentID}
        />
      </View>
      {!!canComment && !disableComments && (
        <>
          {Platform.OS === "web" && <View style={{ height: 120 }} />}
          <View
            style={{
              paddingTop: 10,
              paddingBottom: 50,
              paddingHorizontal: 15,
              backgroundColor: "white",
              shadowOpacity: 0.08,
              shadowOffset: { width: 0, height: -5 },
              shadowRadius: 7,
              elevation: 20,
              ...(Platform.OS === "web"
                ? {
                    boxShadow: "0px -5px 3px rgba(0,0,0,0.08)",
                    height: 120,
                    bottom: 0,
                    position: "fixed"
                  }
                : {}),
              width: "100%"
            }}
          >
            <Button
              text={isChatStyle ? "Reply" : "Comment"}
              onPress={onPressNewPostButton}
              icon="0a94f591-4df6-4f80-abc5-2c374cf25eb9"
              textStyle={{ fontWeight: "bold" }}
            />
          </View>
        </>
      )}
      {!!isComposingMessage && (
        <NewPostModal
          onClose={() => setIsComposingMessage(false)}
          userName={userName}
          onSubmit={onSubmitNewComment}
        />
      )}
      <AlertModal alert={alert} setAlert={setAlert} />
    </View>
  );
}
