import React, { useState } from "react";
import {
  View,
  Text,
  ActivityIndicator,
  Alert,
  TouchableOpacity
} from "react-native";
import * as DocumentPicker from "expo-document-picker";
import Firebase from "src/backend/firebase";
import Button from "src/components/Button";
import Icon from "src/components/Icon";

export default function CustomDocumentPicker({
  isNew,
  onUpload,
  useChatStyle
}) {
  const [isUploadingPDF, setIsUploadingPDF] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);

  const pickDocument = (type = "application/pdf") => {
    return new Promise(async (resolve) => {
      const documents = await DocumentPicker.getDocumentAsync({ type });
      resolve(
        documents?.type === "cancel" ||
          !documents?.assets ||
          documents.assets?.length !== 1
          ? null
          : documents.assets[0]
      );
    });
  };

  const onPressUploadPDF = () => {
    pickDocument().then((document) => {
      if (document) {
        const { name, size, uri } = document;
        const sizeInMB = size / 1000000;
        if (sizeInMB <= 10) {
          setIsUploadingPDF(true);
          Firebase.uploadMedia(uri, {
            title: name,
            onProgressUpdate: setUploadProgress
          }).then((url) => {
            setIsUploadingPDF(false);
            setUploadProgress(0);
            onUpload(url, name);
          });
        } else {
          Alert.alert(
            "File too large",
            "Please upload a file smaller than 10MB."
          );
        }
      }
    });
  };

  if (isUploadingPDF)
    return (
      <View
        style={{
          alignItems: "center",
          padding: 10,
          flexDirection: "row"
        }}
      >
        <ActivityIndicator size="small" />
        <Text style={{ fontSize: 16, color: "#aaa", marginLeft: 10 }}>
          {uploadProgress}% uploaded...
        </Text>
      </View>
    );

  if (useChatStyle) {
    return (
      <TouchableOpacity
        activeOpacity={0.7}
        style={{
          paddingVertical: 10,
          justifyContent: "flex-end",
          alignItems: "center",
          width: "65%",
          flexDirection: "row"
        }}
        onPress={onPressUploadPDF}
      >
        <Icon
          icon={
            isNew
              ? "52ad79ad-58b4-4469-a676-3e00c9e02c3b"
              : "1a0fb204-0d7e-4fc2-8c65-66c3f15fb6ab"
          }
          color="#868686"
          size={20}
        />
        <Text style={{ color: "#868686", marginLeft: 10, fontSize: 16 }}>
          {isNew ? "Add" : "Replace"} PDF
        </Text>
      </TouchableOpacity>
    );
  }

  return (
    <Button
      small
      textStyle={{ fontWeight: "bold" }}
      align="left"
      text={isNew ? "Upload PDF" : "Replace PDF"}
      onPress={onPressUploadPDF}
    />
  );
}
