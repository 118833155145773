import React, { useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  ActivityIndicator,
  TouchableOpacity
} from "react-native";
import Glob from "src/globalConstants";
import Button from "src/components/Button";
import InputBox from "src/components/InputBox";
import PostSkeleton from "src/components/activityFeeds/PostSkeleton";
import Checkbox from "src/components/Checkbox";
import Icon from "src/components/Icon";

const centsToDollarString = (cents) => {
  return `${(cents / 100 || 0).toFixed(2)}`;
};

export default function Product({
  productID,
  name,
  price,
  description,
  shippable,
  customQuestions,
  image,
  isEditing = false,
  isNew = false,
  isEditingAProduct,
  onSave = () => {},
  onArchive = () => {},
  onCancel = () => {},
  onPressPurchase = null,
  isLoadingProduct = false,
  isLoadingPurchaseButton = false,
  shrinkText = false
}) {
  const originalPriceString = centsToDollarString(price);
  const [productName, setProductName] = useState(name);
  const [productDescription, setProductDescription] = useState(description);
  const [productIsShippable, setProductIsShippable] = useState(shippable);
  const [productPrice, setProductPrice] = useState(originalPriceString);
  const [productQuestions, setProductQuestions] = useState(
    customQuestions || []
  );
  const [showCustomQuestions, setShowCustomQuestions] = useState(false);
  const [questionResponses, setQuestionResponses] = useState({});

  useEffect(() => {
    setProductPrice(centsToDollarString(price));
  }, [price]);
  useEffect(() => {
    setProductIsShippable(shippable);
  }, [shippable]);

  const fontSizeMultiplier = shrinkText ? 0.8 : 1;
  const isEditingADifferentProduct = isEditingAProduct && !isEditing;
  const hasBeenEdited =
    name !== productName ||
    description !== productDescription ||
    shippable !== productIsShippable ||
    originalPriceString !== productPrice ||
    JSON.stringify(customQuestions) !== JSON.stringify(productQuestions);
  const canSave = productName && productPrice && hasBeenEdited;

  if (isLoadingProduct)
    return (
      <View style={styles.productContainer}>
        <PostSkeleton />
      </View>
    );

  const imageSource = image
    ? { uri: image }
    : Glob.get(productIsShippable ? "cardboardBoxClosed" : "shoppingCart");

  const questionsAreUnanswered =
    showCustomQuestions &&
    Object.keys(questionResponses).length !== customQuestions.length;

  return (
    <View
      style={{
        ...styles.productContainer,
        shadowRadius: isEditing ? 20 : 5,
        opacity: isEditingADifferentProduct ? 0.3 : 1
      }}
    >
      <View
        style={{
          flexDirection: "row"
        }}
      >
        <Image
          source={imageSource}
          style={{
            width: 80,
            height: 80,
            borderRadius: 15,
            resizeMode: "cover"
          }}
        />
        <View style={{ flex: 1, marginLeft: 15 }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between"
            }}
          >
            <View style={{ flex: 3, marginRight: 2 }}>
              {isEditing ? (
                <InputBox
                  placeholder="Title"
                  autoCapitalize="words"
                  flexibleWidth
                  openStyle
                  value={productName}
                  onChangeText={setProductName}
                  isInvalid={hasBeenEdited && !productName}
                />
              ) : (
                <Text
                  style={{
                    fontSize: 18 * fontSizeMultiplier,
                    fontWeight: "bold"
                  }}
                >
                  {name}
                </Text>
              )}
            </View>
            <View style={{ flex: 2, marginLeft: 2, alignItems: "flex-end" }}>
              {isEditing ? (
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginLeft: 15
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18 * fontSizeMultiplier,
                      fontWeight: "bold",
                      marginTop: 10
                    }}
                  >
                    $
                  </Text>
                  <InputBox
                    placeholder="Price"
                    flexibleWidth
                    keyboardType="numeric"
                    openStyle
                    value={productPrice}
                    onChangeText={setProductPrice}
                    isInvalid={hasBeenEdited && !productPrice}
                  />
                </View>
              ) : (
                <Text
                  style={{
                    fontSize: 18 * fontSizeMultiplier,
                    fontWeight: "bold",
                    marginLeft: 15
                  }}
                >
                  ${productPrice}
                </Text>
              )}
            </View>
          </View>
          {isEditing ? (
            <>
              <InputBox
                placeholder="Description"
                flexibleWidth
                multiline
                openStyle
                value={productDescription}
                onChangeText={setProductDescription}
              />
              <Checkbox
                style={{ marginVertical: 15 }}
                checked={productIsShippable}
                text="Require address for shipping"
                onChange={setProductIsShippable}
              />
            </>
          ) : (
            <Text style={{ fontSize: 16 * fontSizeMultiplier, color: "gray" }}>
              {description}
            </Text>
          )}
        </View>
      </View>
      <View>
        {isEditing && (
          <>
            {productQuestions.length > 0 && (
              <Text style={{ fontWeight: "bold", marginTop: 15 }}>
                Required Questions
              </Text>
            )}
            <View style={{ marginTop: 0 }}>
              {productQuestions.map((q, index) => (
                <View
                  key={q.id}
                  style={{
                    marginBottom: 10,
                    flexDirection: "row",
                    alignItems: "center"
                  }}
                >
                  <View style={{ flex: 1, alignItems: "center" }}>
                    <InputBox
                      placeholder="Enter question to ask customer"
                      value={q.question}
                      onChangeText={(text) => {
                        const updatedQuestions = [...productQuestions];
                        updatedQuestions[index].question = text;
                        setProductQuestions(updatedQuestions);
                      }}
                      flexibleWidth
                      style={{ flex: 1 }}
                    />
                  </View>
                  <TouchableOpacity
                    onPress={() => {
                      const updatedQuestions = productQuestions.filter(
                        (_, i) => i !== index
                      );
                      setProductQuestions(updatedQuestions);
                    }}
                    style={{ padding: 10 }}
                  >
                    <Icon
                      icon="ec79581a-c8a5-4021-a5c1-82cc8e51ea00"
                      color="gray"
                      size={20}
                    />
                  </TouchableOpacity>
                </View>
              ))}
              <TouchableOpacity
                onPress={() => {
                  setProductQuestions([
                    ...productQuestions,
                    { question: "", inputType: "text", id: Date.now() }
                  ]);
                }}
                style={{
                  marginTop: 0,
                  backgroundColor: "#f0f0f0",
                  borderRadius: 5,
                  padding: 10,
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              >
                <Icon
                  icon="cde42390-8051-4509-9bfb-462ff507af63"
                  color="gray"
                  size={20}
                />
                <Text
                  style={{
                    color: "gray",
                    marginLeft: 10,
                    fontWeight: "bold"
                  }}
                >
                  {productQuestions.length < 1
                    ? "Ask Custom Questions"
                    : "Add Question"}
                </Text>
              </TouchableOpacity>
            </View>
          </>
        )}
      </View>
      {!isEditing && !onPressPurchase && productIsShippable && (
        <Text
          style={{
            fontSize: 12 * fontSizeMultiplier,
            color: "gray",
            marginTop: 10,
            marginBottom: -10,
            alignSelf: "flex-end"
          }}
        >
          Shippable
        </Text>
      )}
      {!isLoadingProduct && !!onPressPurchase && (
        <>
          {!!showCustomQuestions && (
            <View style={{ marginTop: 10 }}>
              {customQuestions &&
                customQuestions.map((question, index) => (
                  <InputBox
                    key={index}
                    header={question.question}
                    onChangeText={(text) =>
                      setQuestionResponses({
                        ...questionResponses,
                        [question.question]: text
                      })
                    }
                    value={questionResponses[question.question] || ""}
                    style={{ marginTop: 15 }}
                  />
                ))}
            </View>
          )}
          {isLoadingPurchaseButton ? (
            <ActivityIndicator size="large" style={{ marginTop: 15 }} />
          ) : (
            <Button
              text="Purchase"
              style={{
                marginTop: 25,
                marginBottom: -10,
                opacity: questionsAreUnanswered ? 0.3 : 1
              }}
              outline
              small
              disabled={questionsAreUnanswered}
              onPress={() => {
                if (
                  customQuestions &&
                  customQuestions.length > 0 &&
                  !showCustomQuestions
                ) {
                  setShowCustomQuestions(true);
                } else {
                  onPressPurchase(questionResponses);
                }
              }}
            />
          )}
        </>
      )}
      {isEditing && (
        <View
          style={{
            flexDirection: "row",
            width: "100%",
            marginTop: 25,
            marginBottom: -10
          }}
        >
          {!isNew && (
            <Button
              text="Delete"
              textStyle={{ fontWeight: "bold" }}
              flat
              small
              outline
              flexibleWidth
              style={{ flex: 1 }}
              color={Glob.get("dangerRed")}
              onPress={onArchive}
            />
          )}
          <Button
            text="Cancel"
            textStyle={{ fontWeight: "bold" }}
            flat
            small
            outline
            flexibleWidth
            style={{ flex: canSave ? 1 : 2 }}
            color={Glob.get("primaryColor")}
            onPress={onCancel}
          />
          <Button
            text="Save"
            textStyle={{ fontWeight: "bold" }}
            style={{
              flex: canSave ? 2 : 1,
              opacity: canSave ? 1 : 0.3
            }}
            disabled={!canSave}
            flat
            small
            flexibleWidth
            color={Glob.get("primaryColor")}
            onPress={() =>
              onSave({
                name: productName,
                price: productPrice,
                description: productDescription,
                shippable: productIsShippable,
                customQuestions: productQuestions.map(
                  ({ question, inputType }) => ({ question, inputType })
                ),
                isNew,
                productID,
                isEditing
              })
            }
          />
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  productContainer: {
    marginVertical: 15,
    marginHorizontal: 20,
    paddingHorizontal: 15,
    paddingVertical: 25,
    backgroundColor: "white",
    shadowOpacity: 0.15,
    shadowOffset: { width: 0, height: 0 },
    borderRadius: 10,
    shadowRadius: 5,
    opacity: 1
  }
});
