import React, { useState } from "react";
import { TouchableOpacity, Text, View, Platform } from "react-native";
import Moment from "moment";
import Hyperlink from "react-native-hyperlink";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import Rex from "src/globalState";
import Glob from "src/globalConstants";
import Database from "src/backend/database";
import Util from "src/utility";
import Analytics from "src/backend/analytics";
import PostSkeleton from "src/components/activityFeeds/PostSkeleton";
import MediaItem from "src/components/dynamicContent/MediaItem";
import TouchableLink from "src/components/dynamicContent/TouchableLink";

const { width } = Glob.get("dimensions");
const TRUNCATED_MESSAGE_LENGTH = 300;
const PADDING = 20;

export default function Post({
  activityFeedID,
  post,
  postID,
  navigation,
  allUsers,
  userName,
  userID,
  isChatStyle = false,
  disableLikes = false,
  disableComments = false,
  canComment,
  isExpandedView = false,
  portalID,
  commentID = null, // populated if this is a comment
  setAlert = () => {}
}) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [doNotTruncate, setDoNotTruncate] = useState(false);

  const time = post?.timestamp
    ? `${Moment(post.timestamp).format(
        "h:mm A"
      )} • ${Util.friendlyDate(post.timestamp, { isLong: false })}`
    : "";
  const likeCount = Object.keys(post?.likes || {}).length || 0;
  const commentCount = Object.keys(post?.comments || {}).length || 0;
  const liked = post?.likes?.[userID] || false;

  let likesWord = "likes";
  if (commentID || (isChatStyle && !isExpandedView)) likesWord = "";
  else if (likeCount === 1) likesWord = "like";
  let commentsWord = "comments";
  if (isChatStyle) {
    if (!isExpandedView) commentsWord = "";
    else if (commentCount === 1) commentsWord = "reply";
    else commentsWord = "replies";
  } else if (commentCount === 1) commentsWord = "comment";

  const shouldTruncateMessage =
    !isExpandedView &&
    post?.message?.length > TRUNCATED_MESSAGE_LENGTH &&
    !doNotTruncate;
  const posterName = `${post?.user?.firstName || ""} ${post?.user?.lastName ||
    ""}`;
  const userCanModerate = Rex.getSessionMemory("adminPrivileges")?.includes(
    "EditAllPortals"
  );

  const expand = () => {
    if (!isExpandedView && !commentID) {
      Analytics.logEvent("touch_activityFeedPost_expandPost", {
        activityFeedID,
        postID
      });
      navigation.push("activityFeedComments", {
        activityFeedID,
        postID: post?.postID,
        postUser: post?.user,
        userName,
        userID,
        allUsers,
        isChatStyle,
        disableLikes,
        disableComments,
        canComment,
        portalID
      });
    }
  };

  const deleteMessage = () => {
    Analytics.logEvent("touch_activityFeedPost_deletePost", {
      postID,
      ...(commentID ? { commentID } : {})
    });
    setIsDeleting(true);
    if (commentID) {
      Database.deleteActivityFeedComment(activityFeedID, postID, commentID);
    } else {
      Database.deleteActivityFeedPost(activityFeedID, postID).then(() => {
        if (isExpandedView && !commentID) navigation.goBack();
      });
    }
  };

  const onPressDeleteMessage = () => {
    const alertMessage = `As an admin, you have the power to delete this message and remove it from the feed.${
      !commentID && commentCount > 0
        ? ` This will also delete the ${commentCount} associated ${commentsWord}.`
        : ""
    }\n\n${posterName}\n"${(post?.message || "").truncate(
      TRUNCATED_MESSAGE_LENGTH
    )}"`;
    setAlert({
      title: "Delete Message?",
      message: alertMessage,
      cancel: { text: "Cancel" },
      confirm: {
        text: "Delete",
        onPress: deleteMessage
      }
    });
  };

  if (isDeleting)
    return (
      <View style={{ width }}>
        <PostSkeleton />
      </View>
    );

  const Message = () => (
    <Hyperlink
      linkStyle={{
        color: Rex.getConfig()?.colors?.button,
        textDecorationLine: "underline"
      }}
      onPress={(url, text) => {
        if (Platform.OS === "web") Util.openURL(url);
        else if (navigation)
          navigation.push("webNav", {
            title: text
              .replace("https", "")
              .replace("http", "")
              .replace("://", "")
              .replace("www.", ""),
            url
          });
      }}
    >
      {shouldTruncateMessage ? (
        <Text>
          {(post?.message || "").truncate(TRUNCATED_MESSAGE_LENGTH)}
          <Text
            style={{
              color: Rex.getConfig()?.colors?.button,
              fontWeight: "bold",
              opacity: 0.5
            }}
            onPress={() => setDoNotTruncate(true)}
          >
            Show more
          </Text>
        </Text>
      ) : (
        <Text>{post?.message}</Text>
      )}
    </Hyperlink>
  );

  const renderLinks = () => {
    if (!post?.links || post.links.length === 0) return null;

    return post.links.map((link, index) =>
      link.url ? (
        <TouchableLink
          key={index}
          link={link.url}
          text={link.name || link.url}
          type="web"
          navigation={navigation}
          textStyle={{ marginTop: 10 }}
        />
      ) : null
    );
  };

  return (
    <View
      style={[
        {
          width: commentID ? width - 2 * PADDING : width,
          marginLeft: 0,
          padding: PADDING,
          paddingTop: commentID ? 14 : PADDING,
          paddingBottom: 0,
          marginBottom: isExpandedView || commentID ? 0 : 10,
          backgroundColor: "white"
        },
        isExpandedView || commentID || isChatStyle
          ? {
              borderBottomWidth: isExpandedView ? 1 : 2,
              borderBottomColor: isExpandedView ? "#aaa" : "#f8f8f8",
              backgroundColor: "white"
            }
          : {
              shadowOpacity: 0.08,
              shadowOffset: { width: 0, height: 0 },
              shadowRadius: 3,
              elevation: 10,
              ...(Platform.OS === "web"
                ? {
                    boxShadow: "0px 0px 3px rgba(0,0,0,0.08)"
                  }
                : {})
            }
      ]}
    >
      <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <Text style={{ fontWeight: "bold" }}>{posterName}</Text>
        <Text style={{ color: "#8D969D", fontSize: 10 }}>{time}</Text>
      </View>
      {isExpandedView ||
      !!commentID ||
      (!!disableComments && !shouldTruncateMessage) ? (
        <Message />
      ) : (
        <TouchableOpacity onPress={expand}>
          <Message />
        </TouchableOpacity>
      )}
      {!!post?.media && (
        <TouchableOpacity
          onPress={expand}
          disabled={
            isExpandedView ||
            !!commentID ||
            (!!disableComments && !shouldTruncateMessage)
          }
        >
          <MediaItem
            item={{
              type: "image",
              image: post?.media,
              width: commentID ? 0.8 : 0.9
            }}
          />
        </TouchableOpacity>
      )}
      {renderLinks()}
      <View
        style={{
          flexDirection: "row",
          minHeight: 20,
          width: "100%",
          justifyContent: "space-between"
        }}
      >
        {!disableLikes && (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 10,
              paddingRight: 20
            }}
            disabled={!Rex.getLoginStatus()}
            onPress={() => {
              if (commentID) {
                Analytics.logEvent("touch_activityFeedPost_likeComment", {
                  activityFeedID,
                  postID,
                  commentID,
                  liked: !liked
                });
                Database.likeActivityFeedPostComment(
                  activityFeedID,
                  postID,
                  commentID,
                  !liked
                );
              } else {
                Analytics.logEvent("touch_activityFeedPost_likePost", {
                  activityFeedID,
                  postID,
                  liked: !liked
                });
                Database.likeActivityFeedPost(activityFeedID, postID, !liked);
              }
            }}
          >
            <MaterialCommunityIcons
              name={`cards-heart${liked ? "" : "-outline"}`}
              size={20}
              color={liked ? "#c4043e" : "#8D969D"}
            />
            <Text style={{ marginLeft: 5, color: "#8D969D" }}>
              {likeCount} {likesWord}
            </Text>
          </TouchableOpacity>
        )}
        {!commentID && !disableComments && (
          <TouchableOpacity
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 10
            }}
            disabled={isExpandedView}
            onPress={expand}
          >
            <MaterialCommunityIcons
              name={
                commentCount > 0 && !isExpandedView ? "chat" : "chat-outline"
              }
              size={20}
              color="#8D969D"
            />
            <Text style={{ marginLeft: 5, color: "#8D969D" }}>
              {commentCount} {commentsWord}
            </Text>
          </TouchableOpacity>
        )}
        {!!userCanModerate &&
          !!disableLikes &&
          (!!commentID || !!disableComments) && <View />}
        {!!userCanModerate && (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
              paddingVertical: 10,
              paddingLeft: 20
            }}
            onPress={onPressDeleteMessage}
          >
            <MaterialCommunityIcons
              name="trash-can-outline"
              size={20}
              color="#8D969D"
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
}
